<template>
  <div class="flex-row border-b dark:border-warm-gray-700">
    <div class="flex justify-between items-center mb-8">
      <h2 class="text-xl font-extrabold text-warm-gray-700 tracking-wider dark:text-warm-gray-100">Reviewers</h2>
    </div>
  </div>


  <div class=" flex justify-center mb-2 text-warm-gray-600 dark:text-warm-gray-200">
    <div class="w-10/12 lg:w-7/12">

      <div  class="flex justify-between items-center mt-14">
        <span v-if="reviewers.data && reviewers.data.reviewers.length > 0">Showing all reviewers</span>
        <span v-else>No reviewers to show.</span>
        <router-link to="/reviewer/create" type="button" class="py-2 px-4 text-yellow-700 font-semibold hover:text-yellow-800 focus:text-yellow-800 focus:outline-none dark:hover:text-yellow-600 dark:focus:text-yellow-600">Add new reviewer</router-link>
      </div>

       <!-- body field -->
      <div v-if="!isRetrieving" class="mt-4 grid gap-4">
        <!-- loop reviewers -->
        <template v-if="reviewers.data">
          <div  v-for="(reviewer,i) in reviewers.data.reviewers" :key="i" class="border rounded shadow p-4 text-warm-gray-700 dark:text-warm-gray-200 dark:border-warm-gray-700">
            <div class="flex justify-start items-center pb-2 border-b font-bold dark:border-warm-gray-700">
              <BookOpenIcon class="h-6 w-6 mr-2"/>
              {{reviewer.data.name}}
            </div>
            <div class=" text-warm-gray-500 mt-2 text-sm flex justify-between dark:text-warm-gray-400">
              <div>
                {{ reviewer.data.courses.length }} courses under this reviewer
              </div>
            </div>
            <div class=" bg-warm-gray-100 p-2 mt-2 text-sm dark:bg-warm-gray-800">
              {{reviewer.data.description}}
            </div>
            <div class="mt-2 flex justify-between items-center text-sm">
              <div>
                <p class=" text-xs text-warm-gray-500 italic">ID: {{ reviewer.id }}</p>
              </div>
              <div>
                <router-link type="button" :to="`/reviewer/view/${reviewer.id}`" class="text-yellow-700 underline py-2 px-2 hover:bg-yellow-50 focus:bg-yellow-50 dark:hover:bg-warm-gray-800 dark:focus:bg-warm-gray-800">view</router-link>
                <button @click="deleteRev(reviewer.id, reviewer.data.name)" class="text-warm-gray-500 underline py-2 px-2 hover:bg-warm-gray-100 focus:bg-warm-gray-100  dark:hover:bg-warm-gray-800 dark:focus:bg-warm-gray-800">delete</button>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="mt-4 grid gap-4 animate-pulse">
        <div v-for="i in 5" :key="i" class="border rounded shadow p-4 bg-warm-gray-300 h-40 dark:bg-warm-gray-700 dark:border-warm-gray-700">
        </div>
      </div>

    </div>
    <ProcessingModal v-if="isProcessing" />
  </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import { BookOpenIcon} from "@heroicons/vue/24/outline";
import { getAll, deleteReviewer } from '../firebase'
import swal from 'sweetalert2';
import ProcessingModal from '@/components/ProcessingModal';



const reviewers = ref([]);
const isRetrieving = ref(false);
const isProcessing = ref(false);

onMounted(async () => {
  isRetrieving.value = true;
  reviewers.value = await getAll();
  isRetrieving.value = false;
})


const deleteRev = async (revId, name) => {

  const isConfirmed = await swal.fire({
        title: `Delete ${name}?`,
        text: `Are you sure you want to delete this reviewer? All data under this reviewer will be deleted as well.`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#db912a',
        cancelButtonColor: '#4a4848',
        confirmButtonText: 'Yes, delete'
      }).then(result => result.isConfirmed);
      if(!isConfirmed){return false;}

      isProcessing.value = true;
      let res = await deleteReviewer(revId);
      if (!res.data.isError) {
        reviewers.value = await getAll();
        swal.fire(
            'Success!',
            `Reviewer has been deleted.`,
            'success'
        );
      }else{
        swal.fire({
          title: 'Error!',
          text: 'Unable to delete reviewer',
          icon: 'error'
        });
      }
      isProcessing.value = false;
}

</script>
